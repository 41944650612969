@font-face {
  font-family: 'open-sans-condensed';
  src: url("fonts/open-sans-condensed/open-sans-condensed-light.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open-sans-condensed';
  src: url("fonts/open-sans-condensed/open-sans-condensed-bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
}
