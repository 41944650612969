@import 'fonts/open-sans-condensed/open-sans-condensed';
@import 'fonts/fontello/fontello';

body {
  background: url("/images/backdrop.jpg") center center / 100% 100% no-repeat fixed padding-box content-box #1b2024;
  color: white;
  padding: 0 0.2em 1em 0.2em;
}

a {
  color: rgba(#000, 0);
}

h1 {
  font: bold 3em "open-sans-condensed", sans-serif;
  text-align: center;
}

.grid {
  display: grid;
  gap: 0.5em;
  grid-template-columns: repeat(auto-fit, 9em);
  justify-content: center;
}

.card {
  position: relative;
  width: 9em;
  height: 9em;
  color: white;
  background-color: rgba(128, 128, 128, 0.8);
}

.icon {
  position: absolute;
  top: 0.3em;
  left: 0;
  width: 100%;
  margin: auto;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  font-size: 4em;
}

.label {
  position: absolute;
  left: 0.4em;
  bottom: 0.25em;
  font: 1.2em "open-sans-condensed", sans-serif;
  text-align: left;
}
