@font-face {
  font-family: 'fontello';
  src: url("fonts/fontello/fontello.woff2?59967027") format('woff2'),
       url("fonts/fontello/fontello.woff?59967027") format('woff');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?59967027#fontello') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-warning:before { content: '\21'; } /* '!' */
.icon-plus-circled:before { content: '\2b'; } /* '+' */
.icon-thumbs-up-alt:before { content: '\31'; } /* '1' */
.icon-google:before { content: '\38'; } /* '8' */
.icon-help-circled:before { content: '\3f'; } /* '?' */
.icon-calendar-plus-o:before { content: '\41'; } /* 'A' */
.icon-megaphone-1:before { content: '\42'; } /* 'B' */
.icon-camera:before { content: '\43'; } /* 'C' */
.icon-globe:before { content: '\45'; } /* 'E' */
.icon-cloud-flash-inv:before { content: '\46'; } /* 'F' */
.icon-wind:before { content: '\47'; } /* 'G' */
.icon-id-card-o:before { content: '\4c'; } /* 'L' */
.icon-meteo-france:before { content: '\4d'; } /* 'M' */
.icon-paper-plane:before { content: '\50'; } /* 'P' */
.icon-cloud:before { content: '\54'; } /* 'T' */
.icon-picture:before { content: '\57'; } /* 'W' */
.icon-calendar-empty:before { content: '\61'; } /* 'a' */
.icon-binoculars:before { content: '\62'; } /* 'b' */
.icon-chart-line-1:before { content: '\63'; } /* 'c' */
.icon-down-big:before { content: '\64'; } /* 'd' */
.icon-eye:before { content: '\65'; } /* 'e' */
.icon-flag-checkered:before { content: '\66'; } /* 'f' */
.icon-globe-alt:before { content: '\67'; } /* 'g' */
.icon-signal:before { content: '\68'; } /* 'h' */
.icon-info-circled:before { content: '\69'; } /* 'i' */
.icon-book:before { content: '\6c'; } /* 'l' */
.icon-location:before { content: '\6d'; } /* 'm' */
.icon-ogn:before { content: '\6f'; } /* 'o' */
.icon-flight:before { content: '\70'; } /* 'p' */
.icon-rain-inv:before { content: '\72'; } /* 'r' */
.icon-star:before { content: '\73'; } /* 's' */
.icon-wrench:before { content: '\74'; } /* 't' */
.icon-cloud-sun:before { content: '\77'; } /* 'w' */
.icon-puzzle:before { content: '\7a'; } /* 'z' */
